import React, { useEffect } from "react";
import { Link } from "gatsby";
import classNames from "classnames";

const HeroBannerWithVideo = ({ video }) => {
  useEffect(() => {
    // document.getElementById("videoPlayer").play();
  });
  return (
    <section className="hero-banner hero-banner-video lg:h-screen w-screen">
      {video && video.src && (
        <video id="videoPlayer" playsInline controls>
          <track kind="captions"></track>
          <source src={video.src}></source>
        </video>
      )}
    </section>
  );
};

const HeroBannerButton = ({ button }) => {
  const buttonClassName =
    "bg-transparent	text-white rounded-3xl sm:text-xs	border-white border-2 hover:bg-white hover:text-black lg:py-[15px] lg:px-[50px] px-[10px] self-center	";
  return button?.url.startsWith("/") ? (
    <Link
      to={button.url}
      state={{ subject: button.subject, defaultText: button.default }}
      className={buttonClassName}
    >
      {button.text}
    </Link>
  ) : (
    <a
      href={button.url}
      target="_blank"
      rel="noreferrer"
      className={buttonClassName}
    >
      {button.text}
    </a>
  );
};

const HeroBannerWithImage = ({ image, children, buttons, fullscreen }) => {
  const imageClassName = classNames(
    "w-screen object-cover",
    { "lg:h-screen": !fullscreen },
    { "h-screen": fullscreen }
  );
  return (
    <section className="hero-banner hero-banner-image overflow-hidden	relative">
      <img src={image.src} alt="heroBannerImage" className={imageClassName} />

      <div className="mx-auto absolute top-0 left-0 w-full h-full grid place-items-center">
        {children}
      </div>

      <div className="absolute bottom-[2%] md:bottom-[10%] flex flex-col md:flex-row  gap:2 justify-center	md:gap-3 w-full ">
        {buttons &&
          buttons.map((button) => <HeroBannerButton button={button} />)}
      </div>
    </section>
  );
};

const Title = ({ text }) => {
  return (
    <h1 className="text-4xl lg:text-6xl text-white drop-shadow text-center px-8">
      {text}
    </h1>
  );
};

export const HeroBanner = ({
  kind,
  image,
  video,
  title,
  buttons,
  fullscreen,
}) => {
  return kind === "image" ? (
    <HeroBannerWithImage
      image={image}
      buttons={buttons}
      fullscreen={fullscreen}
    >
      {title && <Title text={title} />}
    </HeroBannerWithImage>
  ) : (
    <HeroBannerWithVideo video={video}>
      {title && <Title text={title} />}
    </HeroBannerWithVideo>
  );
};
