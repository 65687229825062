import { Link } from "gatsby";
import React, { useState } from "react";
import { CollapsibleMenu } from "./CollapsibleMenu";
import classNames from "classnames";
import Logo from "../images/logo_blanc_sans_texte.png";

const StaticMenuItem = ({ children, className }) => {
  return <li className={classNames("pr-8", className)}>{children}</li>;
};

const StaticMenu = ({ toggleCollapse }) => {
  return (
    <ul className="flex text-xl flex-1 justify-end">
      <StaticMenuItem className="hidden lg:inline-block">
        <Link to="/contact">contact</Link>
      </StaticMenuItem>

      <StaticMenuItem>
        <button className="focus:outline-none text-3xl -mt-8" onClick={toggleCollapse}>
          &#9776;
        </button>
      </StaticMenuItem>
    </ul>
  );
};

export const Header = ({ solid }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div
      className={classNames(
        "user-header absolute top-0 left-0 w-full py-8 flex text-white z-10",
        { "bg-primary": solid }
      )}
    >
      <CollapsibleMenu
        isCollapsed={isCollapsed}
        toggleCollapse={() => setIsCollapsed(!isCollapsed)}
      />

      <Link className="text-xl pl-8" to="/">
        <img src={Logo} alt="logo" className="lg:w-24	w-12" />
      </Link>

      <StaticMenu toggleCollapse={() => setIsCollapsed(!isCollapsed)} />
    </div>
  );
};
