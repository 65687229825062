import { Link } from "gatsby";
import React from "react";
import Logo from "../images/logo_blanc_sans_texte.png";

export const Footer = () => {
  const linkClass="text-white border-l-2 border-white	px-3 leading-10";
  return (
    <footer
      className="w-full bg-primary border-t-2 lg:flex lg:flex-row-reverse hidden"
    >
      <Link to="/" className="lg:w-14	w-14 border-l-2 border-white px-3 my-auto"><img src={Logo} alt="logo" /></Link>
      <Link to="/contact"><h3 className={linkClass}>Contact</h3></Link>
      <Link to="/donation"><h3 className={linkClass}>Nous soutenir</h3></Link>
      <h3 className={linkClass}>Newsletters</h3>
      <Link to="/contact"><h3 className={linkClass}>Besoin de priéres</h3></Link>
    </footer>
  );
};
