import React, { useState } from "react";
import { Link } from "gatsby";
import classNames from "classnames";

const CollapsibleSubMenu = ({ title, to, items }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const linkClassNames =
    "block py-3 duration-200 text-md lg:text-3xl hover:font-semibold whitespace-nowrap";

  return (
    <li className="flex flex-col justify-center	">
      {to ? (
        <Link
          className={classNames(linkClassNames, "opacity-75 border-b")}
          to={to}
        >
          {title}
        </Link>
      ) : (
        <button
          className={classNames(
            linkClassNames,
            "opacity-75 border-b text-left"
          )}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {title}
        </button>
      )}

      <ul
        className={classNames(
          "transition-[max-height] duration-700 ease-in	overflow-hidden ",
          { "max-h-96": !isCollapsed, " max-h-0": isCollapsed }
        )}
      >
        {items &&
          items.map((item) => {
            return (
              <li
                key={item.to}
                className="pl-4 py-1 lg:text-xl text-md hover:font-semibold"
              >
                <Link to={item.to}>{item.title}</Link>
              </li>
            );
          })}
      </ul>
    </li>
  );
};

export const CollapsibleMenu = ({ isCollapsed, toggleCollapse }) => {
  return (
    <div
      className={classNames(
        "collapsible-menu fixed text-black top-0 right-0 bg-white h-full z-10 overflow-hidden transition-width duration-700",
        { "w-screen lg:w-1/3": !isCollapsed, "w-0": isCollapsed }
      )}
    >
      <div className="text-right pr-8 pt-8">
        <button className="focus:outline-none" onClick={toggleCollapse}>
          {!isCollapsed && "X"}
        </button>
      </div>

      <ul className="px-8 lg:pt-28">
        <CollapsibleSubMenu title="Accueil" to="/" />
        <CollapsibleSubMenu title="Nous connaître" to="/nous" />
        <CollapsibleSubMenu title="Nos pasteurs" to="/pasteurs" />
        <CollapsibleSubMenu
          title="Vie d'église"
          items={[
            { title: "Relation d'aide", to: "/relation-aide" },
            { title: "Groupes de maison", to: "/osmose" },
            { title: "Pep's", to: "/enfance" },
            { title: "Les ados", to: "/ados" },
            { title: "Connexion", to: "/jeunesse" },
            { title: "Les ainés", to: "/aines" },
          ]}
        />
        <CollapsibleSubMenu title="Prédications" to="/predications" />
        <CollapsibleSubMenu title="Nous soutenir" to="/donation" />
        <CollapsibleSubMenu title="Je veux servir" to="/contact" />
      </ul>
    </div>
  );
};
