import * as React from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import { HeroBanner } from "../components/HeroBanner";
import { Helmet } from 'react-helmet';

export const Layout = ({ children, heroBanner, solidHeader, pageTitle }) => {
  return (
    <React.Fragment>
      <Helmet> 
        { pageTitle && <title>{pageTitle}</title>}
        <link rel="icon" type="image/png" href="logo_noir_sans_texte.png" />
      </Helmet>
      <Header solid={solidHeader} />

      {heroBanner && (
        <HeroBanner
          kind={heroBanner.kind}
          title={heroBanner.title}
          image={heroBanner.image}
          video={heroBanner.video}
          buttons={heroBanner.buttons}
          fullscreen={heroBanner.fullscreen}
        />
      )}

      <main>{children}</main>
      <Footer/>
    </React.Fragment>
  );
};
